import { graphql } from "gatsby"
import React from "react"

import Img from "../components/common/Img"
import LocalizedLink from "../components/common/LocalizedLink"
import Hero, { colors, titleColors, variations } from "../components/Hero/Hero"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import {
  selectNotFoundPageContent,
  selectProductGroups,
  selectTranslations,
} from "../graphql/selectors"
import s from "./404.module.scss"

const backgroundImg = "/images/pages/404/404-img.png"

export default function NotFoundPage(props) {
  const { data, pageContext } = props
  const { langCode, localePath, pagePathsByLangCode } = pageContext
  const translations = selectTranslations(data)
  const notFound = selectNotFoundPageContent(data)

  return (
    <Layout
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
    >
      <SEO {...notFound.seo} lang={langCode} />
      <Hero
        color={colors.grenadier}
        title="404"
        titleColor={titleColors.bright}
        variation={variations.notFound}
      />
      <div className={s.content}>
        <Img
          alt="404"
          className={s.productImage}
          objectFit="contain"
          objectPosition="center"
          placeholderHidden
          src={backgroundImg}
        />
        <p>{notFound.pageNotFoundText}</p>
        <p>
          <LocalizedLink to="/menu/">{notFound.redirectLinkText}</LocalizedLink>
          {notFound.redirectLinkAdditionalText}
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query NotFoundPage($locale: String) {
    ...LayoutFragment
    ...NotFoundPageFragment
  }
`
